<template>
  <b-overlay :show="formShow" style="height: 20vh; background-color: white;" rounded="sm" no-fade>
    <div v-show="userDataInfo != null" style="font-size: 1.4rem;" class="mt-2 text-center bg-info p-2 ">
      Hello
      <strong>{{ userDataInfo ? userDataInfo.name : 'N/A' }} {{ userDataInfo ? userDataInfo.surname : 'N/A' }}</strong
      >, welcome to Solstice

      <h3 class="muted mt-2">{{ dateNow }}</h3>
      <div class="image-container">
        <img :src="require('@/assets/images/solstice.jpg')" />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';
import { kFormatter } from '@core/utils/filter';
import moment from 'moment';
import homeStoreModule from './homeStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,

    AppTimeline,
    AppTimelineItem,
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.checkUser();
  },

  data() {
    const dateNow = moment(new Date()).format('YYYY-MM-DD');

    return {
      dateNow,
      timeLineArray: [],
      formShow: false,
      userDataInfo: null,
    };
  },

  methods: {
    kFormatter,

    checkUser() {
      this.formShow = true;

      this.userId = parseInt(this.userData.id);

      store
        .dispatch('home/getUserById', { id: this.userId })
        .then((response) => {
          this.userDataInfo = response.data;

          if (response.data.status != 'active') {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem('userData');
            router.push({ name: 'auth-login' });
          } else {
            this.formShow = false;
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style scoped>
.image-container {
  width: 90vw;
  max-width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* Gradually increase opacity to 1 */
  }
}
</style>
